<template>

  <div class="button_group">
    <button class="mb-button--primary bw5" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_1x2)">{{i18nConst.COMMON['CROSS'][$store.state.country]}}</button>
<!--    <button class="btn02 bw3" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER}"-->
<!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER)"><i class="fa fa-balance-scale"></i> 핸디캡</button>-->
    <button class="mb-button--primary bw5 ml-5" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)">{{i18nConst.COMMON['SPECIAL'][$store.state.country]}}</button>
<!--    <button class="btn02 bw3" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_LIVE}"-->
<!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_LIVE)"><i class="fa fa-television"></i> Live</button>-->
  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";
  import i18nConst from "../../common/i18nConst";

  export default {
    name: "SportsLinksComp",
    props: {
      displayPosition: {
        type: Number,
        default() {
          return sportsConst.GAME_DISPLAYPOSITION_1x2;
        }
      },
    },
    data(){
      return {
        i18nConst:i18nConst,
        sportsConst,
        disp: this.displayPosition ? this.displayPosition : sportsConst.GAME_DISPLAYPOSITION_1x2,
      }
    },
    methods:{
      changeSportsDisplay(disp) {
        //SportsOriginal.vue -> $on
        this.disp = disp
        this.$store.commit(RECEIVE_DISPLAY_POSTION, 0);
        this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
      }
    },
  }
</script>

<style scoped>
  .sports-links{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .sports-links button {
    width: 24%;
    line-height: 40px;
    font-size: 14px;
    background: #2d2e35;
    border-radius: 3px;
    transition: 200ms all;
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 4px, rgb(255 255 255 / 22%) 1px 1px 4px -2px inset;
    color: #c8c8c7;
    font-weight: bold;
  }
  .sports-links .active{
    color: #1c1c25!important;
    font-size: 14px;
    font-weight: bold;
    background: linear-gradient(rgb(255, 182, 22) 0%, rgb(249, 201, 95) 100%) !important;
  }
</style>