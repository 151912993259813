<template>

    <!-- 모바일 푸터 메뉴 -->

    <div class="sports_bottom_menu">
        <div class="menu_item" @click="leftBarHandle">
            <img src="../../assets/images/icon/common/league.svg" alt="">
            <span style="margin-top: 2px">{{i18nConst.COMMON['SPORTS_LEAGUE'][$store.state.country]}}</span>
        </div>
        <div class="menu_item">
            <img src="../../assets/images/logo/betstoa/logo2.png" style="height: 58px;width: 58px" alt="">
        </div>
        <div class="menu_item" @click="rightBarHandle">
            <img src="../../assets/images/icon/common/cart.svg" alt="">
            <span style="margin-top: 2px"> {{i18nConst.COMMON['BET_CART'][$store.state.country]}}</span>
            <div class="cart_item_count">
                {{$store.state.sportsCartItems.length}}
            </div>
        </div>
    </div>

</template>

<script>
    import {RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE_DELALL} from "../../store/mutation-types";
    import sportsConst from "../../common/sportsConst";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "MobileSportsFootMenuComp",

        data() {
            return {
                i18nConst:i18nConst,
                sportsConst: sportsConst
            }
        },
        methods: {
            leftBarHandle() {
                this.$bus.$emit('leftBarHandle', true)
            },
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', true)
            },
        }
    }
</script>

<style scoped>
    .sports_bottom_menu {
        width: 100%;
        display: none;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        height: 58px;
        background-color: #082c73;
    }

    .sports_bottom_menu .menu_item {
        width: 33.3%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff9ff;
        position: relative;
        cursor: pointer;
    }

    .sports_bottom_menu .menu_item img {
        height: 25px;
    }

    .sports_bottom_menu .menu_item span {
        padding: 3px 0;
    }

    .sports_bottom_menu .menu_item .cart_item_count {
        width: 20px;
        height: 20px;
        position: absolute;
        top: -3px;
        left: calc(50% + 8px);
        padding: 3px;
        border-radius: 50%;
        background-color: #c51a1b;
    }

    .sports_bottom_menu .stock {
        width: 100%;
        position: absolute;
        background-color: #5d5e63;
        height: 48px;
        top: -48px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .sports_bottom_menu {
            display: flex !important;
        }
    }


</style>